import React, { useEffect, useContext } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import Login from "./pages/Login/Login"
import Main from "./pages/Main"
import ToastContexWrapper from "./contexts/ToastContexWrapper"
import Toast from "./components/Toast"
import ProtectedRoute from "./pages/ProtectedRoute"
import { Verify } from "./pages/TwoFactor"

function App() {
  let navigate = useNavigate()
  const routeChange = (path: string) => {
    navigate(path, { replace: true })
  }

  return (
    <ToastContexWrapper>
      <Routes>
        <Route
          path="/login"
          element={<Login logIn={true} routeChange={routeChange} />}
        />
        <Route path="*" element={<Main />} />
        <Route
          path="/logout"
          element={<Login logIn={false} routeChange={routeChange}></Login>}
        />
        <Route
            path="/2fa/verify"
            element={
              <Verify />
            }
          />
      </Routes>
    </ToastContexWrapper>

  )
}

export default App
