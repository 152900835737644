import SectionWrapper from "../../components/SectionWrapper"
import Button from "../../components/Button"
import { verifyTwoFactor } from "../../api/api-ts"
import LoginGraphic from "../Login/components/LoginGraphic"
import Logo from "../../components/Logo"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { ToastContext } from "../../contexts/ToastContexWrapper"
import { VerifyInput } from "../../components/TwoFactor"


export const Verify = () => {

  let navigate = useNavigate()

  return (

    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-min-h-screen tw-bg-typography-light">

      {/** The following div is only displayed for lg screens and up.
       * Will render login graphics on the left side. */}
      <div className="tw-hidden lg:tw-block tw-overflow-hidden tw-h-full tw-w-fit">
        <LoginGraphic />
      </div>
      {/** The following div is only displayed for screen sizes up to (not including) lg.
       * Will render logo as a banner on top of the screen.
       */}
      <div className="lg:tw-hidden tw-pt-[10%] tw-pb-[5%] tw-bg-primary-soft-green">
        <Logo
          aligned={true}
          styles="tw-w-24 tw-h-24 tw-w-40 tw-h-40 sm:tw-w-48 sm:tw-h-48 tw-m-auto"
        ></Logo>
      </div>
      <VerifyInput onCancel={() => navigate("/logout", { replace: true })} onSuccess={() => navigate("/")}/>
    </div>
  )
}