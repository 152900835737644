import React, { useEffect, useState } from "react"
import SectionWrapper from "../../components/SectionWrapper"
import Button from "../../components/Button"
import { HttpError, enableTwoFactor, rotateTwoFactor } from "../../api/api-ts"
import { useNavigate } from "react-router"
import { UserAccount } from "../../types/dataTypes"
import ModalPopup from "../Installation/components/ModalPopup"
import { VerifyInput } from "../../components/TwoFactor"

type SetupProps = {
  account?: UserAccount
}

enum SetupState {
  Setup,
  Scan,
  Verify,
  Error,
  Success
}

export const Setup = ({ setupProps }: { setupProps: SetupProps }) => {

  let [setupState, setSetupState] = useState<SetupState>(SetupState.Setup)
  let [otpUrl, setOtpUrl] = useState<string>()
  let [errorMessage, setErrorMessage] = useState<string>()

  let navigate = useNavigate()
  let QRCode = require("qrcode")
  const setupTwoFactor = async () => {
    setSetupState(SetupState.Scan)
    let otpUrlResponse = { uri: "" }
    try {
      if (!setupProps.account?.two_factor_enabled) {
        otpUrlResponse = await enableTwoFactor()
      } else {
        otpUrlResponse = await rotateTwoFactor()
      }
      setOtpUrl(otpUrlResponse.uri)
    } catch (error){
      if (error instanceof HttpError){
        setErrorMessage(error.message)
        setSetupState(SetupState.Error)
      }
    }

  }

  useEffect(() => {
    if (otpUrl !== undefined && setupState == SetupState.Scan) {
      let canvas = document.getElementById("qr-code-canvas")
      QRCode.toCanvas(canvas, otpUrl, function(error: Error) {
        if (error) setSetupState(SetupState.Error)
        console.log("success!")
      })

    } else {
      console.log("Loading...")
    }
  }, [otpUrl])

  function renderState(setupState: SetupState) {
    switch (setupState) {
      case SetupState.Scan:
        return (
          <div className="tw-text-center">
            <canvas id="qr-code-canvas" className={"tw-mx-auto"}></canvas>
            <Button id="enable-2fa" type="button" size="large" variant="primary" styles="tw-mt-4 tw-bg-green-400 tw-border-green-400"
                    onClick={() => {
                      setSetupState(SetupState.Verify)
                    }}> Next </Button>
          </div>)
      case SetupState.Verify:
        return (
          <ModalPopup open={true} onClose={() => {
          }} headline={""}>
            <VerifyInput onCancel={() => setSetupState(SetupState.Setup)}
                         onSuccess={() => setSetupState(SetupState.Success)}
                         onError={() => setSetupState(SetupState.Setup)} // User needs to setup new token if failure
            />
          </ModalPopup>
        )
      case SetupState.Error:
        return (<p className={"tw-text-md tw-text-red-500"}>{`Something bad happened (${errorMessage}), press the button to the left to try again.`}</p>)

      case SetupState.Success:
        return (
          <ModalPopup open={true} onClose={() => {
            navigate("/")
          }} headline={""}>
            <div className={"text-center"}>
              <h1 className={"tw-text-lg tw-font-bold"}>Two Factor Setup Successful</h1>
              <Button id="home-2fa" type="button" size="large" variant="primary" styles="tw-mt-4 tw-bg-green-400 tw-border-green-400"
                      onClick={() => {
                        navigate("/")
                      }}> Home </Button>
            </div>
          </ModalPopup>
        )

      case SetupState.Setup:
        return ("")

      default:
        return ("")
    }
  }

  return (
    <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
      <script src="node_modules/qrcode/build/qrcode.js"></script>
      <div className={"tw-text-lg tw-font-bold tw-mb-6"}>Enable Two-Factor Authentication</div>
      <div className={"tw-grid tw-grid-cols-2"}>
        <div>
          <p>Admins are required to enable two factor authenticaton.</p>
          <p className={"tw-mt-2"}>1. Click <div className={"tw-inline tw-font-bold"}> {(setupProps && setupProps.account && setupProps.account.two_factor_enabled) ? "Rotate 2FA" : "Enable 2FA"}</div> below. A QR-Code will show up.</p>
          <p>2. Scan the code with an OTP authentication app like Google Authenticator.</p>
          <p>3. After scanning the QR-Code, click <div className={"tw-inline tw-font-bold"}>next</div> to verify.</p>
          {setupState == SetupState.Setup ? <Button id="enable-2fa" type="button" size="large" variant="primary" styles="tw-mt-4 tw-bg-green-400 tw-border-green-400"
                  onClick={setupTwoFactor} disabled={setupState !== SetupState.Setup}>
            {(setupProps && setupProps.account && setupProps.account.two_factor_enabled) ? "Rotate 2FA" : "Enable 2FA"}
          </Button> : ""}
        </div>
        <div>
          {renderState(setupState)}
        </div>
      </div>
    </SectionWrapper>
  )

}