import {
  Alarm,
  Annotation,
  AnomalyGraphDataMap,
  Asset,
  AssetCategory,
  Building,
  DeviceModelConfig,
  ModelTemplate,
  ModelType,
  Organization,
  Room,
  User,
  UserPreferencesForCategory,
} from "../types/dataTypes"
import { chunk } from "../functions/chunk"
import { getAnomalyDetectionResults } from "./api"

var pick = require('lodash/pick');
function setBase() {
  if (process.env.REACT_APP_API_KEY === undefined || process.env.REACT_APP_API_URL === undefined) {
    return "http://localhost:5000"
  } else {
    return process.env.REACT_APP_API_URL
  }
}

export const base =
  process.env.NODE_ENV !== "production"
    ? setBase()
    : window.location.origin

export function withOptions(options?: RequestInit): RequestInit{
  let alteredOptions : RequestInit = options ?? {}
  // Authorization
  alteredOptions.headers = new Headers(alteredOptions.headers)
  alteredOptions.credentials = "include"
  if (process.env.REACT_APP_API_KEY !== undefined) {
    alteredOptions.headers.set("Authorization", "Bearer " + process.env.REACT_APP_API_KEY )
    alteredOptions.credentials = "omit"
    // DANGER: Set REACT_APP_DEV_ALLOW_DANGER to allow unsafe operations when using dev agains production backend.
    if (process.env.REACT_APP_DEV_ALLOW_DANGER === undefined && (["POST", "PUT", "DELETE", "PATCH"].includes(alteredOptions.method ?? "GET"))){
      alteredOptions.headers.delete("Authorization")
    }
  }
  // Client Tracking
  alteredOptions.headers.set('X-Soundsensing-ApiClient', 'soundsensing-server-frontend')
  alteredOptions.headers.set('X-Soundsensing-ApiOrganization', 'soundsensing')
  return alteredOptions
}

export class HttpError extends Error {

  statusCode: number

  constructor(statusCode: number, message: string) {
    super(message)
    this.statusCode = statusCode
  }
}

export const getModelTemplates = async (device_ids: string[], model_ids: string[], model_names: string[],
                                        start_time?: Date, end_time?: Date, active_at?: Date, active_after?: Date, include_devices?: Boolean) => {
  let url = `${base}/v1/ml/templates`
  let params = new URLSearchParams(
    {},
  )
  if (start_time) {
    params.append("start_time", (Math.floor(start_time.getTime() / 1000)).toString())
  }
  if (end_time) {
    params.append("end_time", (Math.floor(end_time.getTime() / 1000)).toString())
  }
  if (active_at) {
    params.append("active_at", (Math.floor(active_at.getTime() / 1000).toString()))
  }
  if (active_after) {
    params.append("active_after", Math.floor(active_after.getTime() / 1000).toString())
  }
  if (include_devices !== undefined) {
    let incl = include_devices ? 1 : 0
    params.append("include_devices", incl.toString())
  }
  device_ids.forEach(id => {
    params.append("device", id)
  })
  model_ids.forEach(id => {
    params.append("id", id)
  })
  model_names.forEach(name => {
    params.append("name", name)
  })
  url = url + "?" + params
  let requestInit: RequestInit = withOptions({method: "GET"})
  let response = await fetch(url, requestInit)
  let data = await response.json()
  return data.data

}

export const getAnnotations = async (device_id?: string[],
                                     organization_id?: string[],
                                     model_template_id?: string,
                                     alarm_id?: string[]): Promise<Annotation[]> => {
  let url = `${base}/v1/annotations`
  let params = new URLSearchParams(
    {},
  )

  if (device_id !== undefined) {
    device_id.forEach(element => {
      params.append("device_id", element)
    })
  }
  if (organization_id !== undefined) {
    organization_id.forEach(element => {
      params.append("organization_id", element)
    })
  }
  if (model_template_id !== undefined) {
    params.append("model_template_id", model_template_id)
  }
  if (alarm_id !== undefined) {
    alarm_id.forEach(element => {
      params.append("alarm_id", element)
    })
  }

  url = url + "?" + params
  let requestInit: RequestInit = withOptions({method: "GET"})
  let response = await fetch(url, requestInit)
  let data = await response.json()
  return data.data

}

export const postAnnotation = async (annotation: Annotation) => {
  let url = `${base}/v1/annotations`
  let body = JSON.stringify(annotation)
  let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const getUserPreferences = async (): Promise<UserPreferencesForCategory[]> => {
  let url = `${base}/v1/userpreferences`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}

export const updateUserPreferences = async (preference: UserPreferencesForCategory): Promise<UserPreferencesForCategory> => {
  let url = `${base}/v1/preferences/${preference.id}`
  let body = JSON.stringify(preference)
  let options = withOptions({ method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const getOrganizations = async (): Promise<Organization[]> => {
  let url = `${base}/v1/organizations`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}

export const getAssets = async (): Promise<Asset[]> => {
  let url = `${base}/v1/assets`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}

export const putAsset = async (asset: Asset): Promise<Asset> => {
  let url = `${base}/v1/assets/${asset.id}`
  let apiCompatilbeAsset = pick(asset,
    ["id",
      "name",
      "description",
      "room_id",
      "registered_by",
      "created_at",
      "model",
      "nice_name",
      "room_position_id",
      "parent_id",
      "asset_category_id",
      "devices"])
  let body = JSON.stringify(apiCompatilbeAsset)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return {...asset, ...data["data"]} // Return along with previously added extra properties
}

export const getAssetCategories = async (): Promise<AssetCategory[]> => {
  let url = `${base}/v1/asset_categories`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}

export const postAsset = async (asset: Asset) => {
  let url = `${base}/v1/assets`

  if (asset.parent_id == "") {
    delete asset.parent_id
  }

  if (asset.room_position_id == "") {
    delete asset.room_position_id
  }

  let body = JSON.stringify(asset)
  console.log(body)
  let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const postBuilding = async (building: Building) => {
  let url = `${base}/v1/buildings`
  let body = JSON.stringify(building)
  let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const putBuilding = async (building_id: string, newName?: string, metaChanges?: {
  [key: string]: any
}): Promise<Building> => {
  let url = `${base}/v1/buildings/${building_id}`
  let content: { [key: string]: any } = {}
  if (newName) {
    content["name"] = newName
  }
  if (metaChanges) {
    if (Object.keys(metaChanges).includes("muted_to") && typeof metaChanges["muted_to"] == "number") {
      metaChanges["muted_to"] = metaChanges["muted_to"] / 1000
    }
    content["meta"] = metaChanges
  }
  let body = JSON.stringify(content)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

//TODO: Maybe delete this and use the one above.
export const updateBuilding = async (building: Building) => {
  let url = `${base}/v1/buildings/${building.id}`
  let body = JSON.stringify(building)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const postRoom = async (room: Room) => {
  let url = `${base}/v1/rooms`
  // Generate room id if not supplied
  let body = room.id === "" ? JSON.stringify({ ...room, id: undefined }) : JSON.stringify(room)
  let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

//TODO: Maybe delete this and use the one above.
export const updateRoom = async (room: Room) => {
  let url = `${base}/v1/rooms/${room.id}`
  let body = JSON.stringify(room)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const createDeviceDeployment = async (deviceToDeploy: {
  device_id: string,
  room_id: string,
  description: string,
  start_time: number,
  assets: string[],
}) => {

  let url = `${base}/v1/devicedeployments`

  let body = JSON.stringify(deviceToDeploy)
  let options = withOptions({ method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})

  let response = await fetch(url, options)

  let data = await response.json()
  console.log(data)
  return data["data"]
}

export const updateOrganizationDevices = async (orgId: string, deviceIds: string[]) => {
  let url = `${base}/v1/organizations/${orgId}`
  const devices = deviceIds.map(id => ({ id }))
  const options = {
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    method: "PUT",
    body: JSON.stringify({ devices }),
  }
  let response = await fetch(url, options)
  let data = await response.json()
  return data["data"]
}

export const uploadImage = async (file: File, onUploadProgress: any): Promise<any> => {
  let formData = new FormData()
  formData.append("file", file)

  let url = `${base}/path/to/imageupload`
  let body = formData
  let options = withOptions({method: "POST", body: body, headers: new Headers({
    "content-Type": "multipart/form-data",
  })})

  let response = await fetch(url, options)

  let data = await response.json()
  return data["data"]
}

export const getImage = async (): Promise<any> => {
  let url = `${base}/path/to/getimage`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}

export const getUser = async (user_id: string): Promise<User> => {
  let url = `${base}/v1/users/${user_id}`
  let response = await fetch(url, withOptions({method: "GET"}))
  let data = await response.json()
  return data["data"]
}


export async function getAnomalyDetectionResultParallel(concurrency: number, modelTemplates: ModelTemplate[], deviceId: string, startTime: Date, endTime: Date) {
  let resultDict: AnomalyGraphDataMap = {}
  let modelTemplateChunks = chunk(modelTemplates, concurrency)
  for (const mltChunk of modelTemplateChunks) {
    let requests = mltChunk.map(mlt => getAnomalyDetectionResults(deviceId, startTime, endTime, [mlt.id], "end_time"))
    let results = await Promise.all(requests)
    for (const res of results) {
      if (res.length > 0) {
        resultDict[res[0]["model_template_id"]] = res
      }
    }
  }
  return resultDict
}

export const putAlarmTyped = async (alarm_id: string, alarm: Alarm): Promise<Alarm> => {
  let url = `${base}/v1/alarm/${alarm_id}`
  let body = JSON.stringify(alarm)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  if (response.status !== 201) {
    throw new HttpError(response.status, "Put Alarm Failed")
  }
  let data = await response.json()
  return data["data"]
}


export async function getDeviceModelConfig(device_id: string[]): Promise<DeviceModelConfig[]>{
  let url = `${base}/v1/devicemodelconfig`
  let params = new URLSearchParams()
  for (let did of device_id){
    params.append('device_id', did)
  }
  let response = await fetch(url + '?' + params.toString(), withOptions({method: "GET"}))
  const data = await response.json()
  return data['data']
}

export async function postDeviceModelConfig(deviceModelConfig: DeviceModelConfig): Promise<DeviceModelConfig>{
  let url = `${base}/v1/devicemodelconfig`
  let body = JSON.stringify(deviceModelConfig)
  let options = withOptions({ method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  const data = await response.json()
  return data['data']
}

export async function putDeviceModelConfig(deviceModelConfig: DeviceModelConfig): Promise<DeviceModelConfig>{
  let url = `${base}/v1/devicemodelconfig/${deviceModelConfig.id}`
  // Api requires these do be deleted apriori.. Api should perhaps avoid doing that.
  delete deviceModelConfig.id
  delete deviceModelConfig.created_at
  delete deviceModelConfig.creator_id

  let body = JSON.stringify(deviceModelConfig)
  let options = withOptions({method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
  let response = await fetch(url, options)
  const data = await response.json()
  return data['data']
}

export async function getModelTypes(): Promise<ModelType[]> {
  let url = `${base}/v1/modeltype`
  let response = await fetch(url, withOptions({method: "GET"}))
  const data = await response.json()
  return data['data']
}

export const postApiRotate = async (user_id: string): Promise<User>=> {
  let url  = `${base}/v1/users/${user_id}/apikey`
  let response = await fetch(url, withOptions({method: "POST"}))
  if (response.status !== 200) {
    throw new HttpError(response.status, "Api Rotate Failed")
  }
  let data = await response.json()
  return data["data"]
}

export const enableTwoFactor = async (): Promise<{uri: string}> => {
  let url  = `${base}/v1/2fa/setup`
  let response = await fetch(url, withOptions({method: "POST"}))
  if (response.status !== 201) {
    throw new HttpError(response.status, "Setup 2FA Failed")
  }
  let data = await response.json()
  return data["data"]
}

export const rotateTwoFactor = async (): Promise<{uri: string}> => {
  let url  = `${base}/v1/2fa/setup`
  let response = await fetch(url, withOptions({method: "PUT"}))
  if (response.status !== 201) {
    throw new HttpError(response.status, "Rotate 2FA Failed")
  }
  let data = await response.json()
  return data["data"]
}

export const verifyTwoFactor = async (code: string) => {
  let url  = `${base}/v1/2fa/verify`
  let body = JSON.stringify({ "code": code, "next_url": "/" })
  let response = await fetch(url, withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })}))
  return response
}