import Button from "../Button"
import SectionWrapper from "../SectionWrapper"
import { useNavigate } from "react-router"
import { useContext, useEffect, useState } from "react"
import { ToastContext } from "../../contexts/ToastContexWrapper"
import { verifyTwoFactor } from "../../api/api-ts"

export const VerifyInput = ({onCancel, onSuccess, onError}: {onCancel: Function, onSuccess: Function, onError?: Function}) => {

  const navigate = useNavigate()
  const toastContext = useContext(ToastContext)
  let [inputs, setInputs] = useState<Array<HTMLInputElement>>([])

  useEffect(() => {
    setInputs(Array.from(document.querySelectorAll("input[type=\"text\"]")))
  }, [])

  useEffect(() => {
    if( inputs.length == 6){
      inputs[0].focus()
    }
  }, [inputs])

  let submitCode = async () => {
    if (inputs !== undefined && inputs.length === 6) {
      let final_value = ""
      for (let i = 0; i < 6; i++) {
        final_value += inputs[i].value
      }
      let response = await verifyTwoFactor(final_value)
      if (response.ok) {
        onSuccess()
      } else {
        if( onError !== undefined){
          onError() //Can define optional on error
        }
        toastContext("Wrong Verification Code, try again", 5, "danger")
        for (let i = 0; i < 6; i++) {
          inputs[i].value = ""
        }
      }
    }
  }

  function handleChange(input: HTMLInputElement, index: number) {
    const value = input.value.replace(/[^0-9]/g, "") // Allow only digits
    input.value = value // Set the value in the current input
    if (value && index < inputs.length - 1) {
      inputs[index + 1].focus() // Move to the next input
    }
  }

  function handleBackspace(event: React.KeyboardEvent, index: number) {
    if (event.key === "Backspace" && !(event.currentTarget as HTMLInputElement).value && index > 0) {
      inputs[index - 1].focus() // Move to the previous input
    }
    if (event.key === "Enter" && inputs.length === 6) {
      submitCode()
    }
  }

  return (
    <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
      <div className={"tw-text-lg tw-font-bold tw-mb-6 text-center"}>Verify Two-Factor Authentication Code</div>
      <div className={"tw-grid tw-gap-5"}>
        <div className="tw-justify-items-center text-center">
          <p>Please enter the code generated by your authenticator app</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4">

          <div className="tw-flex tw-items-center tw-space-x-2">
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 0)}
              onKeyDown={(event) => handleBackspace(event, 0)}
            />
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 1)}
              onKeyDown={(event) => handleBackspace(event, 1)}
            />
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 2)}
              onKeyDown={(event) => handleBackspace(event, 2)}
            />
            <span className="tw-text-2xl tw-mx-2 text-slate-700">-</span>
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 3)}
              onKeyDown={(event) => handleBackspace(event, 3)}
            />
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 4)}
              onKeyDown={(event) => handleBackspace(event, 4)}
            />
            <input
              type="text"
              maxLength={1}
              className="tw-w-10 tw-h-10 tw-bg-transparent tw-text-center placeholder:text-slate-400 text-slate-700 tw-text-lg tw-border border-slate-200 tw-rounded-md tw-transition tw-duration-300 ease focus:tw-outline-none focus:border-slate-400 hover:border-slate-300 tw-shadow-sm focus:tw-shadow"
              onInput={(el) => handleChange(el.currentTarget, 5)}
              onKeyDown={(event) => handleBackspace(event, 5)}
            />
          </div>

          <p className="tw-text-xs text-slate-400 tw-mt-4">
            Did you not set up two-factor authentication? Contact <span
            className="tw-font-bold tw-cursor-pointer">support@soundsensing.no</span>
          </p>
        </div>
        <Button type={"submit"} size={"medium"} variant={"primary"} onClick={() => submitCode()}> Submit </Button>
        <Button type={"button"} size={"medium"} variant={"secondary"}
                onClick={onCancel}> Cancel </Button>
      </div>
    </SectionWrapper>
  )
}