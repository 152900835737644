import React from "react"

export type ToastType = "success" | "danger" | "warning" | "unknown"

type ToastProps = {
  text: string
  type?: ToastType
}

/**
 * The component renderes a toast in the lower right corner.
 *
 * @param text: Text that will be displayed inside toast.
 * @param type: Color of toast. Be be any of the colors defined in tailwind.config.js file, but prefrably "danger", "warning" and "success".
 * @returns
 */
export function Toast({ text, type = "unknown" }: ToastProps) {

  return (<div className="tw-w-full">
      <div className="tw-relative">
        <div className={`tw-max-w-[320px] tw-w-fit
          tw-p-4
          tw-text-12 tw-text-primary-main-black tw-font-normal
          tw-bg-status-${type} tw-border tw-border-secondary-darker-off-white tw-rounded-full 
          tw-fixed tw-bottom-10 tw-inset-x-0 tw-mx-auto tw-transform tw-translate-x-10
          tw-z-[100000000]`}>
          <div className="tw-flex">
            <img
              src="icon-information-custom-black.svg"
              alt="information icon"
              className="tw-mr-2"
            />
            <span>
                    {text}
                </span>

          </div>
        </div>
      </div>
    </div>
  )
}

