import { DeviceModelConfig } from "../../../types/dataTypes"
import { Layout } from "plotly.js"


function makeReferenceLine(value : number, name : string, options={ color: 'black' }) {

    const out : Partial<Plotly.Shape> = {
        xref: "paper",
        x0: 0,
        x1: 1,
        y0: value,
        y1: value,
        type: "line",
        line: {
          color: options.color,
          width: 4,
        },
        label: {
          text: `${name} = ${value}`,
          font: { size: 8, color: options.color },
        },
      }
    return out;
}

/* This method takes a deviceModelConfig which has a threshold parameter in training parameters,
  and uses the value to draw thresholds in the anomaly graph*/
export function addCustomDataFromThresholdModel(thresholdModelConfig: DeviceModelConfig | undefined) {

  return (layout: Partial<Layout>, yLowerRange: number, yUpperRange: number) => {

    const reference_lines = [
        { variable: 'critical_level', name: 'Critical threshold', color: 'red', },
        { variable: 'warning_level', name: 'Warning threshold', color: 'orange', },
    ]

    reference_lines.forEach((ref) => {

        // Only add reference line if the value is set
        if (thresholdModelConfig != undefined && thresholdModelConfig.training_parameters
          && Object.keys(thresholdModelConfig.training_parameters).includes(ref.variable)) {

          if (!layout.shapes) {
            layout.shapes = []
          }

          const value = Number(thresholdModelConfig.training_parameters[ref.variable]);
          layout.shapes.push(makeReferenceLine(value, ref.variable, { color: ref.color}))
        }
    })

  }
}
