import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
  getDeviceMetrics,
  getSoundlevels,
  getAnomalyDetectionResults,
} from "../../../../api/api"
import { LinkButton } from "../../../../components/Button/Button"
import SectionWrapper from "../../../../components/SectionWrapper"
import {
  Device,
  DeviceMetric,
  Soundlevel,
  AnomalyDetectionResult, AnomalyGraphDataMap, ModelTemplate, DeviceDeployment, ModelType, DeviceModelConfig,
} from "../../../../types/dataTypes"
import AnalyticsTile from "../Analytics"
import { ConditionRow } from "../../../../types/componentTypes"
import IconStatusPill from "../../../../components/IconStatusPill"
import AnomalyGraph, { createPreviousAlarmXYAndText } from "../../../../components/AnomalyGraph"
import {
  SimpleGraphDates,
  simpleGraphTimeConverter,
} from "../../../../components/AnomalyGraph/Components/SimpleDateSelect"
import {
  getAnomalyDetectionResultParallel,
  getDeviceModelConfig,
  getModelTemplates,
  getModelTypes,
} from "../../../../api/api-ts"
import { chunk } from "../../../../functions/chunk"
import { Link } from "react-router-dom"
import { Data, Layout } from "plotly.js"
import { addCustomDataFromThresholdModel } from "../../../../components/AnomalyGraph/extras/threshold"

import { thresholdModelNames } from '../../../../pages/AssetSettings/AssetSettings'


type QuickViewProps = {
  closeDeviceQuickView: Function
  cmRow: ConditionRow
  key?: any
  onUpdate?: Function
}

type AnalyticTile = {
  title: string
  data: number | string
  status?: string
}

export default function DeviceQuickView({
                                          closeDeviceQuickView,
                                          cmRow,
                                          onUpdate = () => {
                                            return
                                          },
                                        }: QuickViewProps) {
  // TODO: When implementing clicking Analytic Tile to view graph, use setChartTitle
  const [tileAnalytics, setTileAnalytics] = useState<AnalyticTile[]>([])
  const [graphDataLoading, setGraphDataLoading] = useState(true)
  const [anomalyGraphData, setAnomalyGraphData] = useState<AnomalyGraphDataMap>({})
  const [modelTemplates, setModelTemplates] = useState<ModelTemplate[]>([])
  const [device, setDevice] = useState<Device>(cmRow.devices[0])
  const [graphDateTime, setGraphDateTime] = useState<SimpleGraphDates>("Last 7 days")
  const [thresholdModelConfig, setThresholdModelConfigConfig] = useState<DeviceModelConfig>()

  /** Graph data handling */

  useEffect(() => onUpdate())

  useEffect(() => {

    const fetchData = async () => {
      setGraphDataLoading(true)
      // Get data for last 10 minutes
      const minutesOfData = 10
      const now = new Date()
      let startTime = new Date(now.getTime() - minutesOfData * 60 * 1000)
      let endTime = now

      let anomaly_graph_start_time = new Date(now.getTime() - simpleGraphTimeConverter(graphDateTime) * 60 * 60 * 24 * 1000)
      let modelTemplates: ModelTemplate[] = await getModelTemplates([device.id], [], [],
        undefined, undefined, undefined, undefined, false)
      modelTemplates = modelTemplates.filter(mt => mt.enabled && ((mt.end_time === undefined || mt.end_time === null) || mt.end_time > anomaly_graph_start_time.getTime() / 1000))
      let allAnomalyData: AnomalyDetectionResult[] = []
      let anomalyGraphDataResult: AnomalyGraphDataMap = await getAnomalyDetectionResultParallel(4, modelTemplates, device.id, anomaly_graph_start_time, endTime)
      let keys = Object.keys(anomalyGraphDataResult)
      for( const key of keys){
        allAnomalyData.push(...anomalyGraphDataResult[key])
      }
      formatClassificationData(allAnomalyData)

      setAnomalyGraphData(anomalyGraphDataResult)
      setGraphDataLoading(false)
      setModelTemplates(modelTemplates)
    }
    fetchData()
  }, [device, graphDateTime])

  useEffect(() => {

    const createAnalyticsData = async (
      soundLevelData: Soundlevel[],
      metricsData: DeviceMetric[]
    ) => {
      // Check if sound or vibration device
      if (device.type?.model !== "RuuviTag") {
        formatSoundlevels(soundLevelData)
        formatSoundDeviceMetrics(metricsData)
      } else if (device.type?.model === "RuuviTag") {
        formatVibrationDeviceMetrics(metricsData)
      }
    }

    async function fetchData() {
      const minutesOfData = 10
      const now = new Date()
      let startTime = new Date(now.getTime() - minutesOfData * 60 * 1000)
      let endTime = now
      let soundLevelData = await getSoundlevels([device.id], [], startTime, endTime)
      let metricsData = await getDeviceMetrics([device.id], startTime, endTime)

      createAnalyticsData(soundLevelData, metricsData)
    }

    const fetchDeviceModelConfigs = async (deviceDeployment: DeviceDeployment) => {
      // Fetch threshold model config to render thresholds where applicable
      let deviceModelConfigs = await getDeviceModelConfig([deviceDeployment.device_id])
      let modelTypes = await getModelTypes()
      
      const thresholdModelTypesIds = thresholdModelNames.map(name => modelTypes.find(mt => mt.name == name)?.id)
      const now = new Date().getTime() / 1000
      deviceModelConfigs = deviceModelConfigs.filter(dmc => dmc.start_time <= now && (dmc.end_time == undefined || dmc.end_time > now))

      let thresholdModelConfig = deviceModelConfigs.find(dmc => thresholdModelTypesIds.includes(dmc.model_type_id))
      setThresholdModelConfigConfig(thresholdModelConfig)
    }
    const now = new Date().getTime() / 1000
    let deviceDeployment = cmRow.deviceDeployments.find(dd => dd.end_time == undefined || dd.end_time > now)
    if(deviceDeployment != undefined){
      fetchDeviceModelConfigs(deviceDeployment)
    }

    fetchData()


  }, [device])

  // Find latest machine state
  const formatClassificationData = async (classificationData: AnomalyDetectionResult[]) => {
    let title = "Machine state"
    if(tileAnalytics.filter( ta => ta['title'] == title).length > 0){
      return
    }
    let sortedClassificationData = classificationData
      .filter((data: AnomalyDetectionResult) => data?.machine_state !== null) //Filter away all elements without the machine_state key
      .reduce((prev: any, curr: any) => curr.end_time > prev.end_time ? curr : prev, { end_time: 0 }) // Find and return the newest element

    if (sortedClassificationData.end_time > 0) { // Check that the element is not the neutral element from reduce()
      setTileAnalytics(tileAnalytics => [
        ...tileAnalytics,
        {
          title: title,
          data: sortedClassificationData.machine_state ? "On" : "Off",
          status:
            sortedClassificationData.machine_state
              ? "success"
              : "danger",
        },
      ])
    }
  }

  const formatSoundlevels = (soundLevelData: Soundlevel[]) => {
    let title = "Decibel"
    if(tileAnalytics.filter( ta => ta['title'] == title).length > 0){
      return
    }
    let formattedData =
      soundLevelData.map((level: Soundlevel) => level.leq)
        .reduce((average: number, value: number) => {
          return average + value
        }, 0) / soundLevelData.length

    if (soundLevelData.length > 0) {
      setTileAnalytics(tileAnalytics => [
        ...tileAnalytics,
        {
          title: title,
          data: Math.round(formattedData),
          // TODO: Update status check below with real data?
          status:
            formattedData >= 95
              ? formattedData >= 100
                ? "danger"
                : "warning"
              : "success",
        },
      ])
    }
  }

  const formatVibrationDeviceMetrics = async (
    metricsData: DeviceMetric[],
  ) => {

    let title = "Temperature"
    if(tileAnalytics.filter( ta => ta['title'] == title).length > 0){
      return
    }

    let avgTemp = 0
    let avgVibration = 0

    metricsData.forEach((metric: DeviceMetric) => {
      if (metric.data?.temp) {
        avgTemp =
          metricsData.reduce(
            (prev: number, curr: any) => curr.data.temp + prev,
            0,
          ) / metricsData.length
      }
    })

    if (avgTemp !== 0) {
      setTileAnalytics(tileAnalytics => [
        ...tileAnalytics,
        {
          title: title,
          data: Math.round(avgTemp),
          // TODO: Update status check below with real data?
          status:
            avgTemp >= 40 ? (avgTemp >= 50 ? "danger" : "warning") : "success",
        },
      ])
    }
  }

  const formatSoundDeviceMetrics = async (
    metricsData: DeviceMetric[],
  ) => {
    let avgTemp = 0
    let avgHumidity = 0

    metricsData.forEach((metric: DeviceMetric) => {
      if (metric.data?.temp) {
        avgTemp =
          metricsData.reduce(
            (prev: number, curr: any) => curr.data.temp + prev,
            0,
          ) / metricsData.length
      }
      if (metric.data?.humidity) {
        avgHumidity =
          metricsData.reduce(
            (prev: number, curr: any) => curr.data.humidity + prev,
            0,
          ) / metricsData.length
      }
    })

    if (avgTemp !== 0) {
      setTileAnalytics(tileAnalytics => [
        ...tileAnalytics,
        {
          title: "Temperature",
          data: Math.round(avgTemp),
          // TODO: Update status check below with real data?
          status:
            avgTemp >= 40 ? (avgTemp >= 50 ? "danger" : "warning") : "success",
        },
      ])
    }
    if (avgHumidity !== 0) {
      setTileAnalytics(tileAnalytics => [
        ...tileAnalytics,
        {
          title: "Humidity",
          data: Math.round(avgHumidity),
          // TODO: Update status check below with real data?
          status:
            avgHumidity >= 50 ?
              (avgHumidity >= 60 ? "danger" : "warning")
              : "success",
        },
      ])
    }
  }

  let navigate = useNavigate()
  const routeChange = (path: string) => {
    navigate(path)
  }

  const calcAlarmHistoryLink = () => {
    let url = `/alarm?orgId=${device.organization_id}&buildingId=${cmRow.building.id}&roomId=${cmRow.room.id}&assetId=${cmRow.asset.id}`
    return url
  }


  function handleSetDevice(deviceId: String) {
    let selectedDevice = cmRow.devices.find(d => d.id === deviceId)!
    setDevice(selectedDevice)
  }

  return (
    <div>
      <header className="tw-row-2 tw-rounded-normal tw-items-center tw-p-2">
        <div className="tw-mb-2">
        <span className="tw-text-12 tw-leading-12 tw-basis-3/4 tw-text-gray-400">
          {cmRow.building.name ?? cmRow.building.address} {">"} {cmRow.room.nice_name ?? cmRow.room.name}
        </span>
        </div>
        <div className="tw-flex tw-flex-row">
        <span className="tw-grid tw-grid-cols-5 tw-w-full">
          <div className="tw-font-bold tw-text-24 tw-leading-24 tw-col-span-3">
          {cmRow.asset.nice_name ?? cmRow.asset.name}
          </div>
          <div
            className="tw-mt-0.5 tw-px-4 tw-col-span-2 tw-inline-flex tw-place-self-end tw-gap-4"
          >
            <LinkButton styles="tw-hidden lg:tw-block" variant="secondary" size="small" to={calcAlarmHistoryLink()}>
              Alarm History
            </LinkButton>
            <Link to={`/asset/${cmRow.asset.id}/settings`} className="tw-h-9">
              <img className="tw-h-full" src="icon-settings.svg" alt="arrow icon"/>
            </Link>
          <div
            onClick={() => closeDeviceQuickView()}
            className=""
          >
            <img
              src="icon-cross.svg"
              alt="exit icon"
              className="tw-cursor-pointer"
            />
          </div>
            </div>
        </span>
        </div>
      </header>
      <div className="tw-w-full tw-flex tw-flex-wrap tw-flex-row tw-px-10 tw-pb-2 tw-gap-4">
        <div className="tw-flex tw-items-center tw-justify-center tw-text-md">Devices</div>
        {cmRow.devices.map(d => {
            return (
              <IconStatusPill className={""} selected={d.id === device.id} setSelected={handleSetDevice} name={d.serial}
                              id={d.id}
                              key={d.id}
                              icon={<img
                                src={
                                  d.type?.model === "RuuviTag"
                                    ? "icon-device.svg"
                                    : "icon-sound.svg"
                                }
                                alt="device type icon"
                                className="tw-h-5 tw-w-5 tw-text-gray-400"
                              />} status={d.deviceStatus?.status!} />
            )
          },
        )}
      </div>
      <AnomalyGraph device={device} modelTemplates={modelTemplates} anomalyGraphDataMap={anomalyGraphData}
                    alarms={cmRow.alarms.filter(a => a.device_id === device.id)}
                    onUpdateGraphTime={graphTime => setGraphDateTime(graphTime)} selectedGraphTime={graphDateTime}
                    loading={graphDataLoading}
                    addCustomPlotLayout={addCustomDataFromThresholdModel(thresholdModelConfig)}
      />
      {Object.keys(anomalyGraphData).length === 0 && !graphDataLoading &&
        <div className="tw-p-2 tw-italic">No graph data available.</div>}
      {tileAnalytics && tileAnalytics.length > 0 &&
        <>
          <header className="tw-font-bold tw-p-2">
            Real time information
          </header>
          <div className="tw-flex tw-flex-row tw-flex-wrap tw-w-auto tw-mx-10 tw-my-6 tw-gap-6">
            {tileAnalytics.map((tile: AnalyticTile, index: number) => {
              return (
                <div key={index}>
                  <SectionWrapper styles="tw-border tw-w-52 tw-px-6 tw-overflow-hidden tw-relative">
                    <div
                      className={`${tile.status === "success" ? "tw-bg-status-success" : ""
                      } ${tile.status === "warning" ? "tw-bg-status-warning" : ""
                      } ${tile.status === "danger" ? "tw-bg-status-danger" : ""
                      } ${tile.status === "unknown" ? "tw-bg-status-unknown" : ""
                      } tw-absolute tw-h-full tw-w-8 tw-transform -tw-translate-x-11`}
                    />
                    <AnalyticsTile title={tile.title} data={tile.data} />
                  </SectionWrapper>
                </div>
              )
            })}
          </div>
        </>
      }
    </div>
  )
}
