import { useContext, useEffect, useState } from "react"
import LoginGraphic from "./components/LoginGraphic"
import LoginForm from "./components/LoginForm"
import "./login.css"
import { postUser } from "../../api/api"
import Logout from "./components/Logout"
import Logo from "../../components/Logo"

type LoginProps = {
  logIn: boolean
  routeChange: Function
}

export default function Login({ logIn, routeChange }: LoginProps) {
  const [authFailed, setAuthFailed] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (
    email: string,
    password: string,
    remember: boolean
  ) => {
    let response = await postUser(email, password, remember)
    if (response.ok) {
      let next_route = response.headers.get("Content-Location") ?? "/"
      console.log(next_route)
      routeChange(next_route)
    } else {
      setAuthFailed(true);
      console.log("Login failed. Error response: ", response)

      if (response.status === 401) {
        setErrorMessage("Wrong credentials, please try again.");
        setWrongCredentials(true)
      }
      else {
        setErrorMessage("An error occured, please try again.");
      }
    }
  }

  useEffect(() => {
    if (!authFailed && !wrongCredentials) {
      setErrorMessage("");
    }
  }, [authFailed, wrongCredentials]);



  return (
    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-min-h-screen tw-bg-typography-light">

      {/** The following div is only displayed for lg screens and up.
       * Will render login graphics on the left side. */}
      <div className="tw-hidden lg:tw-block tw-overflow-hidden tw-h-full tw-w-fit" >
        <LoginGraphic />
      </div>
      {/** The following div is only displayed for screen sizes up to (not including) lg. 
       * Will render logo as a banner on top of the screen.
      */}
      <div className="lg:tw-hidden tw-pt-[10%] tw-pb-[5%] tw-bg-primary-soft-green">
        <Logo
          aligned={true}
          styles="tw-w-24 tw-h-24 tw-w-40 tw-h-40 sm:tw-w-48 sm:tw-h-48 tw-m-auto"
        ></Logo>
      </div>


      <div className="tw-flex">
        <div className="lg:tw-mx-20 tw-m-auto">
          {logIn && <LoginForm handleLogin={handleLogin} authFailed={authFailed} setAuthFailed={setAuthFailed} wrongCredentials={wrongCredentials} setWrongCredentials={setWrongCredentials} errorMessage={errorMessage} />}
          {!logIn && <Logout routeChange={routeChange}></Logout>}
        </div>
      </div>
    </div>

  )
}
